import htttttp from "../axios"

const newApi = {
	QueryCheck13(queryValue) {
		return htttttp({
			url: "sceua/AppraiseAutoAudit/QueryCheck13",
			method: "post",
		})
	},
	 

}
export default newApi
