import { createRouter, createWebHashHistory } from "vue-router";
import store from "../store/index";
let CONSTS = {
  //静态常量放在这里
  REVISION: "",
};
let env = process.env.NODE_ENV;
console.log(env);
if (env == "development") {
  CONSTS.REVISION = "V3"; //开发revision
} else if (env == "production") {
  CONSTS.REVISION = "V3"; //开发revision
  // CONSTS.REVISION = "V2" //正式revision
}
const routes = [
  {
    path: "/",
    name: "",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
  {
    path: "/system",
    name: "system",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/System.vue"),
    redirect: (to) => {
      const boo = store.state.user.userInfo.OrgCode === "";
      console.info("userxxxx", boo);
      if (boo) {
        return "/system/review/defaultPageM";
      } else {
        return "/system/review/defaultPage";
      }
    },
    children: [
      {
        path: "/system/review",
        name: "review",
        component: () => import("../views/Review.vue"),
        redirect: "/system/review/defaultPage",
        children: [
          {
            path: "/system/review/defaultPage",
            name: "defaultPage",
            component: () => import("../components/review/defaultPage.vue"),
          },
          {
            path: "/system/review/defaultPageM",
            name: "defaultPageM",
            component: () => import("../components/review/defaultPageM.vue"),
          },
          {
            path: "/system/review/reviewSubmit",
            name: "reviewSubmit",
            component: () => import(`../components/review/reviewSubmit.vue`),
          },
          {
            path: "/system/review/serviceReviewSubmit",
            name: "serviceReviewSubmit",
            component: () =>
              import("../components/review/serviceReviewSubmit.vue"),
          },
          {
            path: "/system/review/reviewSubmitV2",
            name: "reviewSubmitV2",
            component: () =>
              import(`../components/review/reviewSubmit${CONSTS.REVISION}.vue`),
          },
          {
            path: "/system/review/serviceReviewSubmitV2",
            name: "serviceReviewSubmitV2",
            component: () =>
              import(
                `../components/review/serviceReviewSubmit${CONSTS.REVISION}.vue`
              ),
          },
          {
            path: "/system/review/reviewList",
            name: "reviewList",
            component: () => import("../components/review/reviewList.vue"),
          },
          {
            path: "/system/review/reviewListV2",
            name: "reviewListV2",
            component: () => import("../components/review/reviewListV2.vue"),
          },
          {
            path: "/system/review/reviewAppeal",
            name: "reviewAppeal",
            component: () => import("../components/review/reviewAppeal.vue"),
          },
          {
            path: "/system/review/reviewHistory",
            name: "reviewHistory",
            component: () => import("../components/review/reviewHistory.vue"),
          },
        ],
      },
      {
        path: "/system/audition",
        name: "audition",
        component: () => import("../views/Audition.vue"),
        children: [
          {
            path: "/system/audition/auditionList",
            name: "auditionList",
            component: () => import("../components/audition/auditionList.vue"),
          },
          {
            path: "/system/audition/auditionListV2",
            name: "auditionListV2",
            component: () =>
              import("../components/audition/auditionListV2.vue"),
          },
          {
            path: "/system/audition/auditionSubmit",
            name: "auditionSubmit",
            component: () =>
              import("../components/audition/auditionSubmit.vue"),
          },
          {
            path: "/system/audition/serviceAuditionSubmit",
            name: "serviceAuditionSubmit",
            component: () =>
              import("../components/audition/serviceAuditionSubmit.vue"),
          },
          {
            path: "/system/audition/auditionSubmitV2",
            name: "auditionSubmitV2",
            component: () =>
              import(
                `../components/audition/auditionSubmit${CONSTS.REVISION}.vue`
              ),
          },
          {
            path: "/system/audition/serviceAuditionSubmitV2",
            name: "serviceAuditionSubmitV2",
            component: () =>
              import(
                `../components/audition/serviceAuditionSubmit${CONSTS.REVISION}.vue`
              ),
          },
          {
            path: "/system/audition/provienceAuditionHistory",
            name: "provienceAuditionHistory",
            component: () =>
              import("../components/audition/provienceAuditionHistory.vue"),
          },
          {
            path: "/system/audition/personalAuditionHistory",
            name: "personalAuditionHistory",
            component: () =>
              import(
                "../components/audition/personalAuditionHistory/personalAuditionHistory.vue"
              ),
          },
          {
            path: "/system/audition/personalAuditionDetail",
            name: "personalAuditionDetail",
            component: () =>
              import(
                "../components/audition/personalAuditionHistory/personalAuditionDetail.vue"
              ),
          },
          {
            path: "/system/audition/servicePersonalAuditionDetail",
            name: "servicePersonalAuditionDetail",
            component: () =>
              import(
                "../components/audition/personalAuditionHistory/servicePersonalAuditionDetail.vue"
              ),
          },
          {
            path: "/system/audition/appealAuditionList",
            name: "appealAuditionList",
            component: () =>
              import(
                "../components/audition/appealAudition/appealAuditionList.vue"
              ),
          },
          {
            path: "/system/audition/appealAuditionSubmit",
            name: "appealAuditionSubmit",
            component: () =>
              import(
                "../components/audition/appealAudition/appealAuditionSubmit.vue"
              ),
          },
          {
            path: "/system/audition/serviceAppealAuditionSubmit",
            name: "serviceAppealAuditionSubmit",
            component: () =>
              import(
                "../components/audition/appealAudition/serviceAppealAuditionSubmit.vue"
              ),
          },
          {
            path: "/system/audition/auditionExport",
            name: "auditionExport",
            component: () =>
              import("../components/audition/auditionExport.vue"),
          },
          {
            path: "/system/audition/dynamicauditionExport",
            name: "dynamicauditionExport",
            component: () =>
              import("../components/audition/dynamicAuditionExport.vue"),
          },
          {
            path: "/system/audition/companyScoreDetail",
            name: "企业分数明细",
            component: () =>
              import("../components/audition/companyScoreDetail.vue"),
          },
          {
            path: "/system/audition/companyAuditScoreDetail",
            name: "企业年度审核明细",
            component: () =>
              import("../components/audition/companyAuditScoreDetail.vue"),
          },
        ],
      },
      {
        path: "/system/EvaluateTarget",
        name: "EvaluateTarget",
        component: () => import("../views/EvaluateTarget.vue"),
        children: [
          {
            path: "/system/evaluatetarget/indexPage",
            name: "indexPageEvaluatetarget",
            component: () =>
              import("../components/evaluatetarget/indexPage.vue"),
          },
          {
            path: "/system/evaluatetarget/childrenPage",
            name: "childrenPageEvaluatetarget",
            component: () =>
              import("../components/evaluatetarget/childrenPage.vue"),
          },
          {
            path: "/system/evaluatetarget/childrenChildPage",
            name: "childrenChildPageEvaluatetarget",
            component: () =>
              import("../components/evaluatetarget/childrenChildPage.vue"),
          },
        ],
      },
      {
        path: "/system/WorkTask",
        name: "WorkTask",
        component: () => import("../views/WorkTask.vue"),
        children: [
          {
            path: "/system/worktask/indexPage",
            name: "indexPageWorktask",
            component: () => import("../components/worktask/indexPage.vue"),
          },
          {
            path: "/system/worktask/childrenPage",
            name: "childrenPageWorktask",
            component: () => import("../components/worktask/childrenPage.vue"),
          },
        ],
      },
      {
        path: "/system/user",
        name: "user",
        component: () => import("../views/User.vue"),
        children: [
          {
            path: "/system/user/menuManagement",
            name: "menuManagement",
            component: () => import("../components/user/menuManagement.vue"),
          },
          {
            path: "/system/user/departmentManagement",
            name: "departmentManagement",
            component: () =>
              import("../components/user/departmentManagement.vue"),
          },
          {
            path: "/system/user/departmentUserManagement",
            name: "departmentUserManagement",
            component: () =>
              import("../components/user/departmentUserManagement.vue"),
          },
          {
            path: "/system/user/userManage",
            name: "userManagsse",
            component: () => import("../components/user/userManage.vue"),
          },
          {
            path: "/system/user/userAccess",
            name: "userAccess",
            component: () => import("../components/user/userAccess.vue"),
          },
          {
            path: "/system/user/userImport",
            name: "userImport",
            component: () => import("../components/user/userImport.vue"),
          },
        ],
      },
      {
        path: "/system/enforcement",
        name: "enforcement",
        component: () => import("../views/Enforcement.vue"),
        children: [
          {
            path: "/system/enforcement/FinementManualConfirmation",
            name: "FinementManualConfirmation",
            component: () =>
              import(
                "../components/enforcement/FinementManualConfirmation.vue"
              ),
          },
          {
            path: "/system/enforcement/EnforcementCasesList",
            name: "EnforcementCasesList",
            component: () =>
              import("../components/enforcement/EnforcementCasesList.vue"),
          },
          {
            path: "/system/enforcement/EnforcementRecordList",
            name: "EnforcementRecordList",
            component: () =>
              import("../components/enforcement/EnforcementRecordList.vue"),
          },
        ],
      },
      {
        path: "/system/enterpriseScore",
        name: "EnterpriseScore",
        component: () => import("../views/EnterpriseScore.vue"),
        children: [
          {
            path: "/system/enterpriseScore/indexPage",
            name: "EnterpriseScoreList",
            component: () =>
              import("../components/enterpriseScore/indexPage.vue"),
          },
        ],
      },
      {
        path: "/system/enterpriseInfo",
        name: "EnterpriseInfo",
        component: () => import("../views/EnterpriseInfo.vue"),
        children: [
          {
            path: "/system/enterpriseInfo/indexPage",
            name: "EnterpriseInfoList",
            component: () =>
              import("../components/enterpriseInfo/indexPage.vue"),
          },
        ],
      },
      {
        path: "/system/changePassword",
        name: "ChangePassword",
        component: () => import("../views/ChangePassword.vue"),
        children: [
          {
            path: "/system/changePassword/indexPage",
            name: "ChangePasswordList",
            component: () =>
              import("../components/changePassword/indexPage.vue"),
          },
        ],
      },
      {
        path: "/system/appraiseAutoAudit",
        name: "AppraiseAutoAudit",
        component: () => import("../views/AppraiseAutoAudit.vue"),
        children: [
          {
            path: "/system/appraiseAutoAudit/indexPage",
            name: "AppraiseAutoAuditList",
            component: () =>
              import("../components/appraiseAutoAudit/indexPage.vue"),
          },
          {
            path: "/system/appraiseAutoAudit/indexPageRepair",
            name: "AppraiseAutoAuditRepair",
            component: () =>
              import("../components/appraiseAutoAudit/indexPageRepair.vue"),
          },
          {
            path: "/system/appraiseAutoAudit/indexPage2",
            name: "AppraiseAutoAuditList2",
            component: () =>
              import("../components/appraiseAutoAudit/indexPage2.vue"),
          },
          {
            path: "/system/appraiseAutoAudit/indexPage2Add",
            name: "AppraiseAutoAuditAdd2",
            component: () =>
              import("../components/appraiseAutoAudit/indexPage2Add.vue"),
          },
          {
            path: "/system/appraiseAutoAudit/indexPage2Add2",
            name: "AppraiseAutoAuditAdd22",
            component: () =>
              import("../components/appraiseAutoAudit/indexPage2Add2.vue"),
          },
          {
            path: "/system/appraiseAutoAudit/formVerify",
            name: "AppraiseAutoAuditFormVerify",
            component: () =>
              import("../components/appraiseAutoAudit/formVerify.vue"),
          },
          {
            path: "/system/appraiseAutoAudit/formVerify2",
            name: "AppraiseAutoAuditFormVerify2",
            component: () =>
              import("../components/appraiseAutoAudit/formVerify2.vue"),
          },
          {
            path: "/system/appraiseAutoAudit/detailsPage",
            name: "AppraiseAutoAuditDetailsPage",
            component: () =>
              import("../components/appraiseAutoAudit/detailsPage.vue"),
          },
          {
            path: "/system/appraiseAutoAudit/detailsPage2",
            name: "AppraiseAutoAuditDetailsPage2",
            component: () =>
              import("../components/appraiseAutoAudit/detailsPage2.vue"),
          },
          {
            path: "/system/appraiseAutoAudit/itemsPage",
            name: "AppraiseAutoAuditItemsPage",
            component: () =>
              import("../components/appraiseAutoAudit/itemsPage.vue"),
          },
          {
            path: "/system/appraiseAutoAudit/singlePage",
            name: "AppraiseAutoAuditSinglePage",
            component: () =>
              import("../components/appraiseAutoAudit/singlePage.vue"),
          },
          {
            path: "/system/appraiseAutoAudit/singlePage2",
            name: "AppraiseAutoAuditSinglePage2",
            component: () =>
              import("../components/appraiseAutoAudit/singlePage2.vue"),
          },
          {
            path: "/system/appraiseAutoAudit/comScoreHistory",
            name: "AppraiseAutoAuditComScoreHistory",
            component: () =>
              import("../components/appraiseAutoAudit/comScoreHistory.vue"),
          },
          {
            path: "/system/appraiseAutoAudit/QueryCheck13",
            name: "检查13项加分情况",
            component: () =>
              import("../components/appraiseAutoAudit/QueryCheck13.vue"),
          },
        ],
      },
      {
        path: "/system/DocumentTraining",
        name: "DocumentTraining",
        component: () => import("../views/DocumentTraining.vue"),
        children: [
          {
            path: "/system/documentTraining/indexPage",
            name: "documentTrainingList",
            component: () =>
              import("../components/documentTraining/indexPage.vue"),
          },
          {
            path: "/system/documentTraining/detailsPage",
            name: "documentTrainingdetails",
            component: () =>
              import("../components/documentTraining/detailsPage.vue"),
          },
        ],
      },
      {
        path: "/system/ExamineRecord",
        name: "ExamineRecord",
        component: () => import("../views/ExamineRecord.vue"),
        children: [
          {
            path: "/system/examineRecord/indexPage",
            name: "examineRecordList",
            component: () =>
              import("../components/examineRecord/indexPage.vue"),
          },
          {
            path: "/system/examineRecord/indexPage2",
            name: "examineRecordList2",
            component: () =>
              import("../components/examineRecord/indexPage2.vue"),
          },
        ],
      },
      {
        path: "/system/AuditQuery",
        name: "AuditQuery",
        component: () => import("../views/AuditQuery.vue"),
        children: [
          {
            path: "/system/auditQuery/indexPage",
            name: "auditQueryList",
            component: () => import("../components/auditQuery/indexPage.vue"),
          },
          {
            path: "/system/auditQuery/detailsPage",
            name: "auditQuerydetails",
            component: () => import("../components/auditQuery/detailsPage.vue"),
          },
        ],
      },
      {
        path: "/system/AdministrativeQuery",
        name: "AdministrativeQuery",
        component: () => import("../views/AdministrativeQuery.vue"),
        children: [
          {
            path: "/system/administrativeQuery/indexPage",
            name: "administrativeQueryList",
            component: () =>
              import("../components/administrativeQuery/indexPage.vue"),
          },
          {
            path: "/system/administrativeQuery/indexPage2",
            name: "administrativeQueryList2",
            component: () =>
              import("../components/administrativeQuery/indexPage2.vue"),
          },
          {
            path: "/system/administrativeQuery/detailsPage",
            name: "administrativeQuerydetails",
            component: () =>
              import("../components/administrativeQuery/detailsPage.vue"),
          },
          {
            path: "/system/administrativeQuery/detailsPage2",
            name: "administrativeQuerydetails2",
            component: () =>
              import("../components/administrativeQuery/detailsPage2.vue"),
          },
          {
            path: "/system/administrativeQuery/singlePage",
            name: "administrativeQuerysinglePage",
            component: () =>
              import("../components/administrativeQuery/singlePage.vue"),
          },
          {
            path: "/system/administrativeQuery/singlePage2",
            name: "administrativeQuerysinglePage2",
            component: () =>
              import("../components/administrativeQuery/singlePage2.vue"),
          },
        ],
      },
      {
        path: "/system/MunicipalLevel",
        name: "MunicipalLevel",
        component: () => import("../views/MunicipalLevel.vue"),
        children: [
          {
            path: "/system/municipalLevel/indexPage",
            name: "municipalLevelList",
            component: () =>
              import("../components/municipalLevel/indexPage.vue"),
          },
          {
            path: "/system/municipalLevel/detailsPage",
            name: "municipalLeveldetails",
            component: () =>
              import("../components/municipalLevel/detailsPage.vue"),
          },
          {
            path: "/system/municipalLevel/detailsPage2",
            name: "municipalLeveldetails2",
            component: () =>
              import("../components/municipalLevel/detailsPage2.vue"),
          },
          {
            path: "/system/municipalLevel/singlePage",
            name: "municipalLevelsinglePage",
            component: () =>
              import("../components/municipalLevel/singlePage.vue"),
          },
          {
            path: "/system/municipalLevel/singlePage2",
            name: "municipalLevelsinglePage2",
            component: () =>
              import("../components/municipalLevel/singlePage2.vue"),
          },
        ],
      },
      {
        path: "/system/MunicipalRepair",
        name: "MunicipalRepair",
        component: () => import("../views/MunicipalRepair.vue"),
        children: [
          {
            path: "/system/municipalRepair/indexPage",
            name: "municipalRepairList",
            component: () =>
              import("../components/municipalRepair/indexPage.vue"),
          },
          {
            path: "/system/municipalRepair/detailsPage",
            name: "municipalRepairdetails",
            component: () =>
              import("../components/municipalRepair/detailsPage.vue"),
          },
          {
            path: "/system/municipalRepair/detailsPage2",
            name: "municipalRepairdetails2",
            component: () =>
              import("../components/municipalRepair/detailsPage2.vue"),
          },
          {
            path: "/system/municipalRepair/singlePage",
            name: "municipalRepairsinglePage",
            component: () =>
              import("../components/municipalRepair/singlePage.vue"),
          },
          {
            path: "/system/municipalRepair/singlePage2",
            name: "municipalRepairsinglePage2",
            component: () =>
              import("../components/municipalRepair/singlePage2.vue"),
          },
        ],
      },
      {
        path: "/system/ObjectionFeedback",
        name: "ObjectionFeedback",
        component: () => import("../views/ObjectionFeedback.vue"),
        children: [
          {
            path: "/system/objectionFeedback/indexPage",
            name: "objectionFeedbackList",
            component: () =>
              import("../components/objectionFeedback/indexPage.vue"),
          },
          {
            path: "/system/objectionFeedback/indexPage2",
            name: "objectionFeedbackList2",
            component: () =>
              import("../components/objectionFeedback/indexPage2.vue"),
          },
          {
            path: "/system/objectionFeedback/detailsPage",
            name: "objectionFeedbackdetails",
            component: () =>
              import("../components/objectionFeedback/detailsPage.vue"),
          },
        ],
      },
      {
        path: "/system/AuditAssignment",
        name: "AuditAssignment",
        component: () => import("../views/AuditAssignment.vue"),
        children: [
          {
            path: "/system/auditAssignment/indexPage",
            name: "auditAssignmentList",
            component: () =>
              import("../components/auditAssignment/indexPage.vue"),
          },
          {
            path: "/system/auditAssignment/detailsPage",
            name: "auditAssignmentdetails",
            component: () =>
              import("../components/auditAssignment/detailsPage.vue"),
          },
          {
            path: "/system/auditAssignment/detailsPage2",
            name: "auditAssignmentdetails2",
            component: () =>
              import("../components/auditAssignment/detailsPage2.vue"),
          },
        ],
      },
      {
        path: "/system/ExpirationConfirmation",
        name: "ExpirationConfirmation",
        component: () => import("../views/ExpirationConfirmation.vue"),
        children: [
          {
            path: "/system/expirationConfirmation/indexPage",
            name: "expirationConfirmationList",
            component: () =>
              import("../components/expirationConfirmation/indexPage.vue"),
          },
          {
            path: "/system/expirationConfirmation/detailsPage",
            name: "expirationConfirmationdetails",
            component: () =>
              import("../components/expirationConfirmation/detailsPage.vue"),
          },
          {
            path: "/system/expirationConfirmation/detailsPage2",
            name: "expirationConfirmationdetails2",
            component: () =>
              import("../components/expirationConfirmation/detailsPage2.vue"),
          },
          {
            path: "/system/expirationConfirmation/singlePage",
            name: "expirationConfirmationsinglePage",
            component: () =>
              import("../components/expirationConfirmation/singlePage.vue"),
          },
          {
            path: "/system/expirationConfirmation/singlePage2",
            name: "expirationConfirmationsinglePage2",
            component: () =>
              import("../components/expirationConfirmation/singlePage2.vue"),
          },
        ],
      },
      {
        path: "/system/EnterpriseApplications",
        name: "EnterpriseApplications",
        component: () => import("../views/EnterpriseApplications.vue"),
        children: [
          {
            path: "/system/enterpriseApplications/indexPage",
            name: "indexPageApplications",
            component: () =>
              import("../components/enterpriseApplications/indexPage.vue"),
          },
        ],
      },
      {
        path: "/system/TextMessage",
        name: "TextMessage",
        component: () => import("../views/TextMessage.vue"),
        children: [
          {
            path: "/system/textMessage/indexPage",
            name: "indexPageTextMessage",
            component: () => import("../components/textMessage/indexPage.vue"),
          },
        ],
      },
      {
        path: "/system/TextComyMessage",
        name: "TextComyMessage",
        component: () => import("../views/TextComyMessage.vue"),
        children: [
          {
            path: "/system/textComyMessage/indexPage",
            name: "indexPageTextComyMessage",
            component: () => import("../components/textComyMessage/indexPage.vue"),
          }
        ],
      },
      {
        path: "/system/TextMessageView",
        name: "TextMessageView",
        component: () => import("../views/TextMessageView.vue"),
        children: [
          {
            path: "/system/textMessageView/indexPage",
            name: "indexPageTextMessageView",
            component: () => import("../components/textMessageView/indexPage.vue"),
          }
        ],
      },
      {
        path: "/system/ScoreRecord",
        name: "ScoreRecord",
        component: () => import("../views/ScoreRecord.vue"),
        children: [
          {
            path: "/system/scoreRecord/indexPage",
            name: "indexPageScoreRecord",
            component: () => import("../components/scoreRecord/indexPage.vue"),
          }
        ],
      },
      {
        path: "/system/Notice",
        name: "Notice",
        component: () => import("../views/Notice.vue"),
        children: [
          {
            path: "/system/notice/indexPage",
            name: "indexNotice",
            component: () => import("../components/notice/indexPage.vue"),
          }
        ],
      },
      {
        path: "/system/ApprovalForm",
        name: "ApprovalForm",
        component: () => import("../views/ApprovalForm.vue"),
        children: [
          {
            path: "/system/approvalForm/indexPage",
            name: "indexApprovalForm",
            component: () => import("../components/approvalForm/indexPage.vue"),
          }
        ],
      },
      {
        path: "/system/DecisionLetter",
        name: "DecisionLetter",
        component: () => import("../views/DecisionLetter.vue"),
        children: [
          {
            path: "/system/decisionLetter/indexPage",
            name: "indexDecisionLetter",
            component: () => import("../components/decisionLetter/indexPage.vue"),
          }
        ],
      },
      {
        path: "/system/ComScores",
        name: "ComScores",
        component: () => import("../views/ComScores.vue"),
        children: [
          {
            path: "/system/comScores/indexPage",
            name: "indexComScores",
            component: () => import("../components/comScores/indexPage.vue"),
          }
        ],
      },
      {
        path: "/system/ComscoresRef",
        name: "ComscoresRef",
        component: () => import("../views/ComscoresRef.vue"),
        children: [
          {
            path: "/system/comscoresRef/indexPage",
            name: "indexComscoresRef",
            component: () => import("../components/comscoresRef/indexPage.vue"),
          }
        ],
      },
      
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
