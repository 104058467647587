import api from "@/http/apis"
import { ElMessage } from "element-plus"
import { sessionStorage } from "@/utils/sessionStorage"
export default ({
  namespaced: "true",
  state: {
    userInfo: "",
    userMenu: [],
  },
  getters: {
  },
  mutations: {
    setUserInfo
      (state, val) {
      state.userInfo = val
      console.info('val',val)
      // localStorage.setItem("ecua_uif",JSON.stringify(val))
      sessionStorage.set('user', state)
    },
    setUserMenu(state, val) {
      state.userMenu = val
      // localStorage.setItem("ecua_umn",JSON.stringify(val))
      sessionStorage.set('user', state)
    }

  },
  actions: {
    async setUserMenu(context, orgCode) {
      const { data } = await api.userApi.getUserMenu(orgCode)
      if (data.IsSuccessful) {
        if (data.ResultObj.length) {
          context.commit('setUserMenu', data.ResultObj)
        } else {
          context.commit('setUserMenu',[])
          ElMessage({
            message: "当前账号没有设置可用的菜单，请联系管理员",
            type: "error",
          })
        }

      } else {
        context.commit('setUserMenu',[])
        if (data.ResultMsg) {
          ElMessage({
            message: data.ResultMsg,
            type: "error",
          })
        } else {
          ElMessage({
            message: '未知错误，请联系管理员',
            type: "error",
          })
        }
      }
    }
  },

})
