import axios from "../axios";

const userApi = {
  loginEncrypted({ loginName, password }) {
    return axios({
      url: "sceua/Account/login",
      method: "post",
      data: {
        loginName,
        password,
      },
    });
  },
  login({ loginName, password }) {
    return axios({
      url: "sceua/Account/login",
      method: "post",
      data: {
        loginName,
        password,
        openid:""
      },
    });
  },
  getUserMenu(orgCode) {
    let urlStr = "";
    if (orgCode === "") {
      urlStr = `sceua/Menu/GetUserMenuLM`;
    } else {
      urlStr = `sceua/Menu/GetUserMenuLC`;
    }
    return axios({
      url: urlStr,
      method: "get",
    });
  },
  saveUserUnit(data) {
    return axios({
      url: `sceua/EUnit/CreateEditSave`,
      method: "post",
      data,
    });
  },
  getUserUnits(data) {
    return axios({
      url: `sceua/EUnit/Query`,
      method: "post",
      data,
    });
  },
  delUserUnitByID(id) {
    return axios({
      url: `sceua/EUnit/Delete`,
      method: "post",
      params: {
        id,
      },
    });
  },
  delUserByID(id) {
    return axios({
      url: `sceua/EUnitUser/Delete`,
      method: "post",
      params: {
        id,
      },
    });
  },
  resetUserByID(id) {
    return axios({
      url: `sceua/Account/ReSetPassWord?userId=${id}`,
      method: "post",
    });
  },
  getUsers(data) {
    return axios({
      url: `sceua/EUnitUser/Query`,
      method: "post",
      data,
    });
  },
  saveUser(data) {
    return axios({
      url: `sceua/EUnitUser/CreateEditSave`,
      method: "post",
      data,
    });
  },
  getCompany(data) {
    return axios({
      url: `sceua/CompanyManage/Query`,
      method: "post",
      data,
    });
  },
  getPersonalUsers(data) {
    return axios({
      url: `sceua/EIBCUser/QueryUserInfo`,
      method: "post",
      data,
    });
  },
  getUserAccessLists(data) {
    return axios({
      url: `sceua/EUserApp/Query`,
      method: "post",
      data,
    });
  },
  getUserRegionList() {
    return axios({
      url: `sceua/EUserApp/QueryUserRegionIds`,
      method: "post",
    });
  },
  getAllRegionList() {
    return axios({
      url: `sceua/CibcRegion/QuerySC`,
      method: "post",
    });
  },
  getRetrievePass(data) {
    return axios({
      url: `sceua/Account/ReFindPassWord`,
      method: "post",
      data,
    });
  },
  getUserStreets(appraiseComId) {
    return axios({
      url: `sceua/EUserApp/QureyComJieDao`,
      method: "post",
      params: { appraiseComId },
    });
  },
  saveUserStreets(data) {
    return axios({
      url: `sceua/EUserApp/SaveComJieDao`,
      method: "post",
      data,
    });
  },
  editinitpassword(data) {
    return axios({
      url: `sceua/Account/editinitpassword`,
      method: "post",
      data,
    });
  },
  SaveHJUserFindBack(data) {
    return axios({
      url: `https://jk.hj.hjxy.org.cn/api/UserFindBack/SaveHJUserFindBack`,
      method: "post",
      data,
    });
  },
  uploadFile(data) {
    return axios({
      url: `https://jk.hj.hjxy.org.cn/file?path=${data.path}&secondPath=${data.secondPath}&fileName=${data.fileName}`,
      // url: `https://jk.hj.hjxy.org.cn/file?path=${data.path}&secondPath=${data.secondPath}&fileName=${data.fileName}`,
      method: "post",
      data: data.file,
    });
  },
  userExcelImport(formData, file) {
    return axios({
      url: `sceua/AppraiseCompany/ImExport`,
      method: "post",
      data: formData,
      file,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      processData: false,
    });
  },
};
export default userApi;
