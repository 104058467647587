let CONSTS = {
	//静态常量放在这里
	BASE_URL: ""
}
let env = process.env.NODE_ENV;
console.log(env)
if (env == 'development') {
	// CONSTS.BASE_URL = "http://221.10.90.153:8097/" //生产环境服务器
	CONSTS.BASE_URL = "https://jk.sc.hjxy.org.cn" //生产环境服务器
	// CONSTS.BASE_URL = "http://221.10.90.140:8096" //正式上线
	// CONSTS.BASE_URL = "http://221.10.90.140:8103" //正式上线
	// CONSTS.BASE_URL = "http://192.168.34.32:8103/" //局域网本地后端服务器
} else if (env == 'production') {
	// CONSTS.BASE_URL = "http://221.10.90.153:8097/" //开发打包端口 
	CONSTS.BASE_URL = "https://jk.sc.hjxy.org.cn" //生产环境服务器
	// CONSTS.BASE_URL = "http://221.10.90.140:8103" //正式上线端口
}
export default CONSTS
