import axios from "axios";
import { ElMessage } from "element-plus";
//在axios的基础之上创建了一个新的axios实例，命名随意，instance是实例的意思
import CONSTS from "../store/consts"

const instance = axios.create({
  baseURL: CONSTS.BASE_URL, //设置服务端的访问问题，跨域没有解决，需要cors来配置跨域
  // timeout: 10000, //用来设置超时，如果请求五秒钟还没求求成功，就会断开连接，报超时的错误
  timeout: 3600000, //用来设置超时，如果请求五秒钟还没求求成功，就会断开连接，报超时的错误
})
//2. 配置axios请求拦截器，在请求之前给拦截下来，把token加上去，再重新通过
instance.interceptors.request.use((config) => {
  // 拦截成功
  // 2.1 获取 token
  const token = localStorage.getItem("cdenv_token");
  // 2.2 将 token 添加到请求头的 Authorization 属性中
  // config.headers.Authorization = 'Bearer ' + token;
  config.headers.Authorization = token
  return config;
}, (err) => {
  // 拦截失败
  return err;
});

// 单独调用axios实例

// axios({
// 	method: 'get',
//     url: "xxxx.xxx",
// 	headers:{Authorization:localStorage.getItem("tokenName")},
// 	data:{},
// 	params:{}
// })

import store from "../store/index"
// import Vuex from 'vuex'
// console.info("userStore before",userStore)


// 3. 配置 axios 响应拦截器
instance.interceptors.response.use((res) => {
  // 响应成功
  if (res.data.ResultData == "loginout") {

    // userStore.commit("resetUserInfo")
    store.commit('user/setUserInfo', '')
    // alert("登录过期，请重新登录")
    ElMessage({
      message: "登录过期，请重新登录",
      type: "error",
      grouping:true
    });
    // localStorage.removeItem("ecc_token")
    // }

  }
  return res; // { data: {} }
}, (err) => {
  // 响应失败
  // 判断错误类型，并将错误的描述添加到 err.message
  if (err && err.response) {
    switch (err.response.status) {
      case 400:
        err.message = '请求错误(400)';
        break;
      case 401:
        err.message = '身份认证失败(401)';
        break;
      case 403:
        err.message = '拒绝访问(403)';
        break;
      case 404:
        err.message = '请求出错(404)';
        break;
      case 408:
        err.message = '请求超时(408)';
        break;
      case 500:
        err.message = '服务器错误(500)';
        break;
      case 501:
        err.message = '服务未实现(501)';
        break;
      case 502:
        err.message = '网络错误(502)';
        break;
      case 503:
        err.message = '服务不可用(503)';
        break;
      case 504:
        err.message = '网络超时(504)';
        break;
      case 505:
        err.message = 'HTTP版本不受支持(505)';
        break;
      default:
        err.message = `连接出错(${err.response.status})!`;
    }
    return {
      //手动返回结果
      data: {
        status: 0,
        type: err.response.status,
        message: err.message
      }
    }
  } else {
    err.message = '服务器连接失败！';
    return {
      //手动返回结果
      data: {
        status: 0,
        message: err.message
      }
    }
  }
});


//4.将创建和配置完成新的axios实例暴露出去
export default instance;
