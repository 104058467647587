import axios from "../axios"

const menuApi = {
	getMenuTree() {
		return axios({
			url: "sceua/Menu/GetMenuTree",
			method: "post",
		})
	},
	getMenuTreeAlt() {
		return axios({
			url: "sceua/Menu/GetMenuByParentId?parentId=3",
			method: "post",
		})
	},
	saveMenuItem(data) {
		return axios({
			url: `sceua/Menu/CreateAndUpdateSave`,
			method: "post",
			data
		})
	},
	delMenuItem(id) {
		return axios({
			url: `sceua/Menu/Delete`,
			method: "post",
			params: { id }
		})
	},
	

}
export default menuApi
